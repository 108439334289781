import React, { useState, useEffect } from 'react';
import BannerImage from './images/temp.png'; // Import your image file
import './Treatment2.css'; // Import CSS file for styling
import SecondSection from './T2secondsection'; // Import SecondSection component
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component

const Treatment3 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after delay
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {/* Loading overlay */}
      <LoadingOverlay loading={loading} />

      {/* First Section */}
      <div className="video-container" style={{ position: 'relative', overflow: 'hidden', backgroundColor: '#f0f0f0' }}>
        {/* Image */}
        <h1 className="video-text">Accessible Psychiatry</h1>
        <img src={BannerImage} alt="Banner" className="fullscreen-video" />

      </div>

      <SecondSection />      
    </div>
  );
};

export default Treatment3;
