import React, { useState, useEffect } from 'react';
import BrainswayVideo from './Media/BrainswayVideoCut.mp4'; // Import your video file
import './Treatment3.css'; // Import CSS file for styling
import SecondSection from './T3secondsection'; // Import SecondSection component
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component

const Treatment3 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after delay
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}> {/* Ensure full width and height */}
      {/* Loading overlay */}
      <LoadingOverlay loading={loading} />

      {/* First Section */}
      <div className="video-container" style={{ position: 'relative', overflow: 'hidden', backgroundColor: '#f0f0f0' }}>
        {/* Video */}
        <h1 className="video-text">Discover Brainsway Deep TMS</h1>
        <video autoPlay loop muted className="fullscreen-video">
          <source src={BrainswayVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      
      {/* Second Section */}
      <SecondSection />
    </div>
  );
};

export default Treatment3;
